<template>
<CRow>
    <CCol sm="12">
        <CCard style="min-height: 120px;">        
            <CCardHeader class="pt-2 pb-1">    
                <h4 class="pt-1 pb-0 float-left">Profile</h4>  
            </CCardHeader>
        </CCard>
    </CCol>
</CRow>
</template>
<script>
export default {
}
</script>